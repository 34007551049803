@tailwind base;
@tailwind components;
@tailwind utilities;

.slateBox {
  @apply border border-slate-400 bg-slate-100 rounded-xl;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border-right: 2px solid #fff;
}
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}
